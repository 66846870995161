import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: { name: 'Home' }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: 'luckDraw' */'@/views/home')
  },
  {
    path: '/winningList',
    name: 'WinningList',
    component: () => import(/* webpackChunkName: 'luckDraw' */'@/views/winningList')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
