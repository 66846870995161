<template>
  <router-view />
</template>

<style lang="scss">
  @import "@/styles/global/reset";

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
  }
</style>